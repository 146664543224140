
import Http from './Http'

export default {
    async getSponsors() {
        return await Http.get(`/advertising/sponsors?type=all`)
    },
    async getAdvertisements(data) {
        return await Http.get(`/advertising/sponsors/placement?${data}`)
    },
    async getSponsorPlacements(sponsor_id, data = '') {
        return await Http.get(`/advertising/sponsors/${sponsor_id}/placements?${data}`)
    },
    async getPlacementTypes() {
        return await Http.get(`/advertising/sponsors/placement/types`)
    },
    async getPlacementTypeStructure(data) {
        return await Http.get(`/advertising/sponsors/placement/structure?${data}`)
    },
    async createSponsorAdvertisement(data){
        return await Http.post(`/advertising/sponsors/placement`, data)
    },
    async editSponsorAdvertisement(id, data){
        return await Http.patch(`/advertising/sponsors/placement/${id}`, data)
    },
    async deleteSponsorAdvertisement(id){
        return await Http.delete(`/advertising/sponsors/placement/${id}`,)
    },
    async getRssAdvertorial() {
        return await Http.get(`advertising/rss_advertorial`)
    },
    async editRssAdvertorial(data) {
        return await Http.post(`advertising/rss_advertorial`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getSponsorById(sponsor_id) {
        return await Http.get(`/advertising/sponsors?id=${sponsor_id}`)
    },
    async getSponsorMedia(data) {
        return await Http.get(`/advertising/sponsors/media?${data}`)
    },
    async createSponsorAttributes(sponsor_id, data) {
        return await Http.post(`/advertising/sponsors/${sponsor_id}/attribute`, data)
    },
    async removeAttribute(sponsor_id, attribute) {
        return await Http.delete(`/advertising/sponsors/${sponsor_id}/attribute/${attribute}`)
    },
    async addSponsor(data) {
        return await Http.post(`/advertising/sponsors`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async editSponsor(sponsor_id, data) {
        return await Http.post(`/advertising/sponsors/${sponsor_id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async getSponsoredPlayers() {
        return await Http.get(`/advertising/sponsors/player`)
    },
    async attachSponsoredPlayer(data) {
        return await Http.patch(`/advertising/sponsors/player/attach`, data)
    },
    async detachSponsoredPlayer(data) {
        return await Http.patch(`/advertising/sponsors/player/detach`, data)
    },
    async addSponsoredMedia(sponsor_id, data) {
        return await Http.post(`/advertising/sponsors/${sponsor_id}/media`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async removeSponsoredMedia(sponsor_id, media_id) {
        return await Http.delete(`/advertising/sponsors/${sponsor_id}/media/${media_id}`)
    },
    async getSponsorWall() {
        return await Http.get(`/advertising/sponsors/wall`)
    },
    async detachSponsorWall(data) {
        return await Http.patch(`/advertising/sponsors/wall/detach`, data)
    },
    async attachSponsorWall(data) {
        return await Http.patch(`/advertising/sponsors/wall/attach`, data)
    },
    async removeSponsor(sponsor_id) {
        return await Http.delete(`/advertising/sponsors/${sponsor_id}`)
    },
    async getDeals() {
        return await Http.get(`/advertising/deals?status=all`);
    },
    async getDeal(id) {
        return await Http.get(`/advertising/deals?id=${id}`);
    },
    async getDealsAll(data) {
        return await Http.get(`/advertising/deals/all?${data}`);
    },
    async getDealsCurrency() {
        return await Http.get(`/advertising/deals/currency`);
    },
    async getDealsRegion() {
        return await Http.get(`/advertising/deals/region`);
    },
    async getDealsSector() {
        return await Http.get(`/advertising/deals/sector`);
    },

    async editDeal(id, data) {
        return await Http.post(`/advertising/deals/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async addDeal(formData) {
        return await Http.post(`/advertising/deals`, formData, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async deleteTeamDeal(id) {
        return await Http.delete(`/advertising/deals/${id}`)
    },

    async getPromotions(data) {
        return await Http.get(`/advertising/deals/promotions?${data}`);
    },
}


